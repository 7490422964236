<script setup>
const dt = ref(null)
const year = new Date().getFullYear()
dt.value = year > '2024' ? ` - ${year}` : ''
</script>

<template>
  <footer class="full footer ">
    <div class="inner-container ">
      <div class="footer-container top">
        <CommonLogo />
        <!-- <div class="top__logo">
          <p>M3.sale</p>
        </div> -->
        <FooterMenu />
        <FooterSide />
      </div>
    </div>
    <div class="inner-container">
      <div class="bottom">
        <p class="bottom__text">{{ $t('footer.copyrights') }}</p>
        <p class="bottom__text">{{ $t('footer.policy') }}</p>
        <span class="footer__copy">&copy; 2024{{ dt }}&nbsp;</span>
      </div>
      <div class="bottom__social">
        <CommonSocial />
      </div>
    </div>
  </footer>
</template>

<style lang='scss'>
.footer-container {
  --gap: 94px;
  // --minColWidth: 20px;
  display: grid;
  grid-template-columns: max-content auto 1fr;
  // grid-template-columns: repeat(12, minmax(var(--minColWidth), 1fr));
  gap: var(--gap);

  @media (width < 1024px) {
    --gap: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (width < 700px) {
    display: flex;
    flex-direction: column;
  }
}

.footer {
  padding-block-start: 40px;
  background: var(--c-text-primary);
  color: var(--c-white);
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;

  @media (width < 700px) {
    padding-block-start: 24px;
  }
}

.top {
  &__logo {
    gap: 16px;
    font-size: 18px;
  }
}

.bottom {
  display: flex;
  justify-content: space-between;
  padding-block: 12px;
  font-size: 14px;
  font-weight: 300;

  @media (width < 700px) {
    flex-direction: column;
    margin-block: 32px;
  }

  &__text {
    @media (width < 700px) {
      margin-block-end: 12px;
    }
  }

  &__social {
    display: none;

    @media (width < 700px) {
      --jc: flex-start;
      display: block;
    }
  }
}
</style>