<script setup lang="ts">
const validationSchema = useValidation(['email'])

async function submit(body: CommonFormBody) {
  await useRedirectFromForm(body)
}
</script>

<template>
  <div class="side">
    <CommonForm
      :title="$t('homePage.joinTitle')"
      type="email"
      name="email"
      :placeholder="$t('homePage.joinPlaceholder')"
      :buttonName="$t('homePage.joinButton')"
      :validationSchema="validationSchema"
      alignment="center"
      placeholderColor="var(--c-text-primary)"
      bcgInput="var(--c-white)"
      bcgButton="var(--c-brand)"
      fzTitleMobile="20px"
      textTransform="uppercase"
      @submit-form="submit"
    />
    <div class="side__social">
      <CommonSocial />
    </div>
  </div>
</template>

<style lang='scss' scoped>
.side {
  --border-radius: 32px;
  max-width: 502px;

  &__social {
    @media (width < 700px) {
      display: none;
    }
  }
}
</style>