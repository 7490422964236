<script setup>
</script>

<template>
  <nav aria-labelledby="footer-navigation">
    <ul
      role="list"
      class="nav"
    >
      <li
        v-for="{title, to, nav} in footerMenu"
        :key="title"
        class="nav__list"
      >
        <NuxtLink 
          :to="to" 
          class="nav__link"
        >
          <span class="nav__list-title">{{ $t(`footer.menu.${title}`) }}</span>
        </NuxtLink>
        <template v-if="nav">
          <ul
            role="list"
            class="nav__nav-inner"
          >
            <li
              v-for="i in nav"
              :key="i.to"
              class="nav__list-item"
            >
              <NuxtLink
                :to="i.to"
                class="nav__link"
              >
                {{ itemName }}
              </NuxtLink>
            </li>
          </ul>
        </template>
      </li>
    </ul>
  </nav>
</template>

<style lang='scss' scoped>
.nav {
  display: flex;
  gap: 32px;

  @media (width < 700px) {
    display: flex;
    flex-direction: column;
    gap:0;
  }  

  &__list-title {
    display: inline-block;
    font-weight: 700;
    margin-block-end: 16px;
  }

  &__list-item {
    margin-block-end: 6px;
  }

  &__link {
    display: inline-block;
    color: var(--c-white);
    text-decoration: none;
    font-size: 14px;font-weight: 500;
  }

  // &__link.router-link-active {
  //   color: var(--c-text-secondary);
  // }
}
</style>