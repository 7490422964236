<script setup lang="ts">
</script>

<template>
  <header 
    ref="header" 
    class="header"
  >
    <CommonLogo width="117px" />
    <div class="header__project">
      <CommonDiscussProject />
    </div>
    <div class="header__menu">
      <HeaderMenu />
      <HeaderButtons />
    </div>
  </header>
</template>

<style lang='scss' scoped>
.header {
  --menu-gap: 61px;
  --menu-padding: 22px;
  padding-block: var(--menu-padding);
  display: flex;
  align-items: center;
  background: var(--c-white);

  @media (width < 1024px) {
    display: none;
  }

  &__menu {
    margin-inline-start: auto;
    display: flex;
    align-items: center;
    gap: var(--menu-gap);

    @media (width < 1024px) {
      display: none;
    }
  }

  &__project {
    flex: 2;
  }
}
</style>

