<!-- eslint-disable vue/multi-word-component-names -->
<script setup>
</script>

<template>
  <div class="container">
    <CommonHeader/>
    <slot />
    <CommonFooter />
  </div>
</template>

<style lang='scss'>
</style>