<script setup lang="ts">
</script>

<template>
  <nav
    class="header__nav nav"
    aria-label="main-navigation"
  >
    <ul
      role="list"
      class="nav__box"
    >
      <li class="nav__link">
        <a
          href="tel:+78005557724"
          class="nav__link"
        >8 800 555 77 24</a>
      </li>
      <li
        v-for="{ to, item } in menuItems"
        :key="to"
        class="nav__item"
      >
        <NuxtLinkLocale
          :to="to"
          class="nav__link"
        >
          {{ $t(`header.${item}`) }}
        </NuxtLinkLocale>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
.nav {
  &__box {
    display: flex;
    justify-content: space-between;
    gap: 24px;
  }

  &__link {
    text-decoration: none;
    color: var(--c-text-primary);
  }

  &__link.router-link-active {
    color: var(--c-brand);
  }
}
</style>
