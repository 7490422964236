<script setup lang="ts">
const route = useRoute()

const token = useCookie<string | null>('access')
const user = useJwtDecode(token.value, 'is_company_admin')
const href = computed(() => user ? `/account-seller/organization` : `/account-customer/layouts`)
const link = ref<string | null>(null)

const getButtonLink = (to: string, button: string) => {
  link.value = route.path.includes('seller') ? 'seller' : 'customer'
  return button === 'login' ? `${to}` : `${to}-${link.value}`
}
</script>

<template>
  <div class="header__side side">
    <HeaderLangSwitch />
    <nav
      v-if="!token"
      class="side__nav"
      aria-label="Sign up and sign in navigation"
    >
      <NuxtLinkLocale
        v-for="{ to, button, className } in authNavButtons"
        :key="to"
        :to="getButtonLink(to, button)"
        :class="['side__button', ...className]"
      >
        {{ $t(`header.${button}-${link}`) }}
      </NuxtLinkLocale>
    </nav>
    <div
      v-if="token"
      class="side__account"
    >
      <CommonAvatar
        background="var(--c-grey-2)"
        name="ab"
      />
      <NuxtLinkLocale :to="href">
        <img
          aria-hidden="true"
          src="/svg/login.svg"
          alt="logout"
          width="30"
          height="30"
        >
      </NuxtLinkLocale>
    </div>
  </div>
</template>

<style lang='scss' scoped>
.side {
  display: flex;
  align-items: center;
  gap: var(--menu-gap);

  &__link-box {
    display: flex;
    gap: 12px;
  }

  &__link,
  &__button {
    text-decoration: none;
  }

  &__link {
    color: var(--c-text-3);
  }

  &__link.router-link-active {
    color: var(--c-brand);
  }

  &__nav {
    display: flex;
    gap: 16px;
  }

  &__account {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
</style>